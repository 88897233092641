.rdp {
  --rdp-cell-size: 37px !important;
  font-size: 12px;
}

@media screen and (min-width: 1280px) {
  .rdp {
    --rdp-cell-size: 40px !important;
    font-size: 14px;
  }
}

.rdp-months {
  justify-content: center;
}
