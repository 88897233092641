@tailwind base;
@tailwind components;
@tailwind utilities;

.rdp {
  --rdp-cell-size: 40px;
}

.tooltip {
  color: white !important;
}

.swiper {
  width: 100%;
  height: 100%;
  position: static !important;
  margin-bottom: 0px !important;
  margin: 20px auto;
}

.lesson-swiper .swiper-slide {
  background-color: black;
  border-radius: 10px;
  margin-right: 18px;
  flex-shrink: 0;
  color: rgba(255, 234, 0, 1);
}

.lesson-swiper .swiper-slide:not(.swiper-slide-active) .discounted-price {
  color: white;
}

.lesson-swiper .swiper-slide-active .content-box {
  background-color: rgba(255, 234, 0, 1);
  color: black !important;
}

.swiper-slide-active h2,
.swiper-slide-active h3,
.swiper-slide-active p,
.swiper-slide-active ul {
  color: black;
}

.admin-branch-swiper {
  max-width: 800px;
}

.admin-branch-swiper .swiper-slide {
  display: flex;
  justify-content: center;
}

.admin-branch-swiper .swiper-button-prev,
.admin-branch-swiper .swiper-button-next {
  color: rgba(255, 255, 255, 0.6);
  scale: 0.6;
}

@media screen and (min-width: 640px) {
  .admin-branch-swiper .swiper-button-prev {
    left: 0;
  }
  .admin-branch-swiper .swiper-button-next {
    right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .admin-branch-swiper .swiper-button-prev {
    left: 5vw;
  }
  .admin-branch-swiper .swiper-button-next {
    right: 5vw;
  }
}
@media screen and (min-width: 1280px) {
  .admin-branch-swiper .swiper-button-prev {
    left: 10vw;
  }
  .admin-branch-swiper .swiper-button-next {
    right: 10vw;
  }
}

@media screen and (min-width: 1440px) {
  .admin-branch-swiper .swiper-button-prev {
    left: 15vw;
  }
  .admin-branch-swiper .swiper-button-next {
    right: 15vw;
  }
}

.branch-swiper .swiper-button-prev,
.branch-swiper .swiper-button-next {
  color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
  position: absolute;
  scale: 0.4;
  top: 37px;
}

.lesson-swiper .swiper-button-prev,
.lesson-swiper .swiper-button-next {
  color: rgba(255, 255, 255, 0.6);
  scale: 1.5;
  position: absolute;
  top: 350px;
  display: none;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: rgba(255, 255, 255, 1);
}

.invisible-scrollbar {
  /* For Internet Explorer, Edge */
  -ms-overflow-style: none;

  /* For Firefox */
  scrollbar-width: none;

  /* For Chrome, Safari, Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.app-container {
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
}

@media screen and (max-width: 640px) {
  .lesson-swiper .swiper-slide {
    margin-bottom: 20px;
  }

  .app-container {
    padding-bottom: calc(60px + env(safe-area-inset-bottom));
  }
}

@media screen and (min-width: 768px) {
  .swiper {
    margin-top: 60px;
  }

  .branch-swiper .swiper-button-prev,
  .branch-swiper .swiper-button-next {
    top: 77px;
  }
}

@media screen and (min-width: 1024px) {
  .swiper {
    margin-top: 15px;
  }

  .branch-swiper .swiper-button-prev,
  .branch-swiper .swiper-button-next {
    top: 37px;
  }
}

@media screen and (min-width: 1280px) {
  .lesson-swiper .swiper-button-prev {
    left: -70px;
  }
  .lesson-swiper .swiper-button-next {
    right: -70px;
  }
  .lesson-swiper .swiper-button-prev,
  .lesson-swiper .swiper-button-next {
    display: block;
  }
  .branch-swiper .swiper-button-prev,
  .branch-swiper .swiper-button-next {
    top: 24px;
    scale: 0.5;
  }
  .app-container {
    padding-bottom: calc(40px + env(safe-area-inset-bottom));
  }
}

@media screen and (min-width: 1700px) {
  .app-container {
    padding-bottom: calc(100px + env(safe-area-inset-bottom));
  }
}
@media screen and (min-width: 2560px) {
  .app-container {
    padding-bottom: calc(200px + env(safe-area-inset-bottom));
  }
}

.voucher {
  display: flex;
}

.special-offer {
  padding: 10px;
  margin-right: 10px; /* adjust as necessary */
  background-color: #ffd700; /* gold color, adjust as necessary */
  border: 2px solid #000; /* black border, adjust as necessary */
  color: #000; /* text color */
}

.highlighted {
  box-shadow: 0px 0px 10px #ffd700; /* adds a glow effect */
  /* additional styling to make it stand out */
}

.expiry-date {
  padding: 10px;
  background-color: #333; /* dark background, adjust as necessary */
  color: #fff; /* white text color */
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 15px 5px rgba(255, 215, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 15px 10px rgba(255, 215, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 15px 5px rgba(255, 215, 0, 0.7);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

input[type='date']::-webkit-calendar-picker-indicator,
input[type='datetime-local']::-webkit-calendar-picker-indicator {
  background: url('./assets/images/calendar.svg') no-repeat;
  border-width: thin;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: url('./assets/images/time.svg') no-repeat;
  border-width: thin;
}
